.testimonialsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Espace entre les éléments */
  }
  
  .testimonialsList .item {
    flex: 1 1 22%; /* Les éléments prennent 22% de la largeur de la ligne */
    min-width: 250px; /* Largeur minimale pour les éléments */
    background-color: #070707;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonialsList .item p {
    font-style: italic;
    color: #ccc8c8;
  }
  
  .testimonialsList .clientname {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .testimonialsList .clientinfo {
    color: #888;
  }
  